<template>
  <b-container>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      spinner-type="grow"
      spinner-variant="primary"
    >
      <FilterComponent
        :store="store" 
        :filter="filter"
        @filter-now="filter = '';changePage(1);" 
        @change-page="changePage($event)"
        @add-new="add()"
        @filter-word="filter = $event"
      />
      
      <CrudComponent
        :store.sync="store"
        :filter="filter"
        :fetch="fetchItems"
        :formerr.sync="formError"
        @editNow="edit"
        @showNow="show"
        @delNow="del"
        @changeNow="changePage"
      />

      <!-- Edit modal -->
      <b-modal
        id="modal"
        centered
        :title="form.formTitle"
        hide-footer
      >
        <b-alert 
          v-if="alert.message" 
          :show="5" 
          dismissible 
          :variant="alert.type" 
          @dismissed="clearAlert"
        >
          {{ alert.message }}
        </b-alert>

        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="$v.form.item.name.$model"
              name="name"
              :state="validateState('name')"
              aria-describedby="name-live-feedback"
            />

            <b-form-invalid-feedback
              id="name-live-feedback"
            >
              This is a required field and must be at least 3 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Slug"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="$v.form.item.slug.$model"
              name="slug"
              :state="validateState('slug')"
              aria-describedby="slug-live-feedback"
            />

            <b-form-invalid-feedback id="slug-live-feedback">
              This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="text-center">
            <b-button
              type="submit"
              variant="primary"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </b-modal>

      <!-- Show modal -->
      <b-modal
        id="show-modal"
        centered
        title="Show Item"
        ok-only
        size="md"
      >
        <ul class="list-group">
          <li
            v-for="(val, index) in form.item"
            :key="index"
            class="list-group-item"
          >
            {{ index + ' : ' + val }}
          </li>
        </ul>
      </b-modal>
    </b-overlay>
  </b-container>
</template>

<style scoped>

</style>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, minLength, alphaNum } from "vuelidate/lib/validators";
import FilterComponent from '@/components/FilterComponent';
import CrudComponent from '@/components/CrudComponent';

export default { 
  name: 'Permissions',
  components: {
    FilterComponent,
    CrudComponent
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        formTitle: 'Create Item',
        mode: 'add',
        item: {
          id: null,
          name: null,
          slug: null
        }
      },
      isBusy: false,
      filter: '',
    }
  },
  validations: {
    form: {
      item: {
        slug: {
          required,
          alphaNum
        },
        name: {
          required,
          minLength: minLength(3)
        }
      }
    }
  },
  computed: {
    ...mapState({ alert: state => state.alert }),
    ...mapState("permission", ['formError']),
    ...mapGetters("permission", ['store'])
  },
  watch: {
    formError(newVal){
      if(!newVal){
        this.$bvModal.hide('modal');
      }
    }
  },
  methods: {
    ...mapActions("permission", [
      "fetchItems", 
      "addItem", 
      "deleteItem", 
      "updateItem", 
      "changePage"
    ]),
    ...mapActions({ clearAlert: 'alert/clear' }),
    
    // 1. Prepare modal for creation of item.
    add() {
      this.$v.$reset();
      this.form.item = {
        id: null,
        name: null,
        slug: null
      }
      this.form.formTitle = 'Create Item:';
      this.form.mode = 'add';
      this.$root.$emit('bv::show::modal', 'modal')
    },

    // Prepare model for updation.
    edit(item, button) {
      this.form = {
        formTitle: 'Edit Item:',
        mode: 'update',
        item: JSON.parse(JSON.stringify(item)) 
        // Deep copy to prevent model binding with store
      };
      this.$root.$emit('bv::show::modal', 'modal', button)
    },

    // 2. Validation after creating form.
    validateState(name) {
      const { $dirty, $error } = this.$v.form.item[name];
      return $dirty ? !$error : null;
    },

    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if(this.form.mode == 'update'){
        this.updateItem(this.form.item);
      }else{
        this.addItem(this.form.item);
      }
    },

    // 4. Show Item details in another Modal.
    show(item, index, button) {
      this.form.item = item;
      this.$root.$emit('bv::show::modal', 'show-modal', button)
    },

    // 5. Delete the item.
    del(id) {
      let res = window.confirm("Are you sure?");
      if(res) this.deleteItem(id);
    },
  }
}
</script>
